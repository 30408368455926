import './App.css';
import Home from './components/Home'

function App() {

  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
